import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from "../../actions/authAction";
import { clearCurrentProfile } from "../../actions/profileAction";

class Navbar extends Component {

    onLogoutClick(e) {
        e.preventDefault();

        this.props.clearCurrentProfile();
        this.props.logoutUser();
    }

    render() {

        const { isAuthenticated, user } = this.props.auth;

        const authLink = (
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">Dashboard</Link>
                </li>

                <li className="nav-item">
                    <a
                        href="/logout"
                        onClick={this.onLogoutClick.bind(this)}
                        className="nav-link"
                        title={ user.name }
                    >Logout
                    </a>
                </li>
            </ul>
        );
        const guestLink = (
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/register">Sign Up</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/login">Login</Link>
                </li>
            </ul>
        );

        return (
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-4">
                <div className="container">
                    <Link className="navbar-brand" to="/">Social Network</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="mobile-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/profiles"> Developers
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/posts">Posts Feed</Link>
                            </li>
                        </ul>

                        {isAuthenticated ? authLink : guestLink }

                    </div>
                </div>
            </nav>
        )
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    clearCurrentProfile: PropTypes.func.isRequired
};

const mapStateToProps = state  => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(Navbar);