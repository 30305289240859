import React, { Component } from 'react';
import { connect } from 'react-redux';
import { registerUser } from './../../actions/authAction';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { clearErrors } from "../../actions/errorAction";

class Register extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirm: '',
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    //компонент примонтировался
    componentDidMount() {
        this.props.clearErrors();

        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/dashboard');
        }
    }

    //когда компонент получил новые props
    //в этот момент, старые props доступны как this.props, а новые props доступны в виде nextProps аргумента функции
    //deprecated
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.errors) {
    //         this.setState({ errors: nextProps.errors });
    //     }
    // }

    //обновляет состояние аналогично объекту, переданному в setState
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.errors) {
            return {
                ...prevState,
                errors: nextProps.errors
            };
        }
        return null;
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const newUser = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password_confirm: this.state.password_confirm
        };

        this.props.registerUser(newUser, this.props.history);
    }

    render() {
        const { errors } = this.state;

        // console.log(this.props.testProps);

        return (
            <div className="register">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <h1 className="display-4 text-center">Sign Up</h1>
                            <p className="lead text-center">
                                Create your account
                            </p>
                            <form noValidate onSubmit={this.onSubmit}>
                                <TextFieldGroup
                                    placeholder="Name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    error={errors.name}
                                />
                                <TextFieldGroup
                                    placeholder="Email"
                                    name="email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={errors.email}
                                    info="This site uses Gravatar so if you want a profile image, use a Gravatar email"
                                />
                                <TextFieldGroup
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    error={errors.password}
                                />
                                <TextFieldGroup
                                    placeholder="Confirm Password"
                                    name="password_confirm"
                                    type="password"
                                    value={this.state.password_confirm}
                                    onChange={this.onChange}
                                    error={errors.password_confirm}
                                />
                                <input type="submit" className="btn btn-info btn-block mt-4" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

//mapStateToProps = state => ({testStore: state}) - мапит состояние со стора в пропс компонента Register -> можно вызвать this.props.testStore
//registerUser = dispatch => ({})

export default connect(mapStateToProps, { registerUser, clearErrors })(withRouter(Register));