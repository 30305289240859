import { applyMiddleware, createStore, compose } from "redux";
import thunk from 'redux-thunk'; //async actions work with devtools and middleware together
import rootReducer from './reducers';

const middleware = [thunk];

const initialState = {};

let devTools = window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
    devTools = a => a;
}

//Создание стора, rootReducer - функции, которые меняют стор
const store = createStore(rootReducer, initialState, compose(
    applyMiddleware(...middleware),
    devTools
));

//подписка на изменение в сторе, store.getState() - получает данные со всего стора
// store.subscribe(() => {
//     console.log('subscribe', store.getState());
// });

export default store;