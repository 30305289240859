
import axios from 'axios';

import {ADD_POST, DELETE_POST, GET_ERRORS, GET_POST, GET_POSTS, LIKE_POST, POST_LOADING} from "./types";

export const addPost = (data) => dispatch => {
    axios.post('/api/post', data)
        .then(res =>
            dispatch({
                type: ADD_POST,
                payload: res.data
            }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const getPosts = () => dispatch => {
    dispatch(setPostLoading());
    axios.get('/api/post')
        .then(res =>
            dispatch({
                type: GET_POSTS,
                payload: res.data
            }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const deletePost = (id) => dispatch => {
    axios.delete(`/api/post/${id}`)
        .then(res =>
            dispatch({
                type: DELETE_POST,
                payload: id
            }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const addLike = (id) => dispatch => {
    axios.post(`/api/post/like/${id}`)
        .then(res =>
            // dispatch(getPosts())
            dispatch({
                type: LIKE_POST,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const removeLike = (id) => dispatch => {
    axios.post(`/api/post/unlike/${id}`)
        .then(res =>
            // dispatch(getPosts())
            dispatch({
                type: LIKE_POST,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const getPost = (id) => dispatch => {
    dispatch(setPostLoading());
    axios.get(`/api/post/${id}`)
        .then(res =>
            dispatch({
                type: GET_POST,
                payload: res.data
            }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const addComment = (postId, data) => dispatch => {
    axios.post(`/api/post/comment/${postId}`, data)
        .then(res =>
            dispatch({
                type: GET_POST,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

export const deleteComment = (postId, commentId) => dispatch => {
    axios.delete(`/api/post/comment/${postId}/${commentId}`)
        .then(res =>
            dispatch({
                type: GET_POST,
                payload: res.data
            }))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data.errors
            })
        );
};

//Post loading
export const setPostLoading = () => {
    return {
        type: POST_LOADING
    }
};