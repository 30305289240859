import React, { Component } from 'react';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import CreateProfile from './components/profile/CreateProfile';
import AddExperience from './components/profile/AddExperience';
import AddEducation from './components/profile/AddEducation';
import Profiles from './components/profile/Profiles';
import Profile from './components/profile/profile/Profile';
import NotFound from './components/error-page/NotFound';
import Posts from "./components/post/Posts";
import Post from "./components/post/single-post/Post";

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from "./actions/authAction";
import { clearCurrentProfile } from "./actions/profileAction";

import PrivateRoute from './components/common/PrivateRoute';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

import './App.css';

//Check for token
if (localStorage.jwtToken) {
    //Set header for axios
    setAuthToken(localStorage.jwtToken);
    // Decode token to get user data
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set current user
    store.dispatch(setCurrentUser(decoded));

    //Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Clear current Profile
        store.dispatch(clearCurrentProfile());
        // Redirect to login
        window.location.href = '/login';
    }
}

class App extends Component {
  render() {
    return (
        <Provider store={ store }>
            <Router>
                <div className="App">
                    <Navbar/>

                    <Route exact path="/" component={ Landing }/>
                    <div className="container">
                        <Route exact path="/profiles" component={ Profiles }/>
                        <Route exact path="/profile/:handle" component={ Profile }/>
                        <Route exact path="/posts" component={ Posts }/>
                        <Route exact path="/post/:id" component={ Post }/>
                        {/*<Route exact path="/register" render={() => <Register testProps={'test'}/>}/>*/}
                        <Route exact path="/register" component={ Register }/>
                        <Route exact path="/login" component={ Login }/>
                        <Switch>
                            <PrivateRoute exact path="/dashboard" component={ Dashboard }/>
                        </Switch>
                        <Switch>
                            <PrivateRoute exact path="/create-profile" component={ CreateProfile }/>
                        </Switch>
                        <Switch>
                            <PrivateRoute exact path="/edit-profile" component={ CreateProfile }/>
                        </Switch>
                        <Switch>
                            <PrivateRoute exact path="/add-experience" component={ AddExperience }/>
                        </Switch>
                        <Switch>
                            <PrivateRoute exact path="/add-education" component={ AddEducation }/>
                        </Switch>
                        <Route exact path="/not-found" component={ NotFound }/>
                    </div>

                    <Footer/>
                </div>
            </Router>
        </Provider>
    );
  }
}

export default App;
