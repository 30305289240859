import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { deletePost, addLike, removeLike } from "../../actions/postAction";

class PostItem extends Component {

    onDeleteClick(id) {
        this.props.deletePost(id);
    }

    onLikeClick(id) {
        this.props.addLike(id)
    }

    onUnlikeClick(id) {
        this.props.removeLike(id)
    }

    findUserLike(likes) {
        const { auth } = this.props;
        if (likes.filter(like => like.user === auth.user.id).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        const { post, auth, showActions } = this.props;

        const userAvatar = (<img
            className="rounded-circle d-none d-md-block"
            src={post.user.avatar}
            alt=""
        />);

        return (
            <div className="card card-body mb-3">
                <div className="row">
                    <div className="col-md-2">
                        { post.profile ?
                            (<Link to={`/profile/${post.profile.handle}`}>
                                { userAvatar }
                            </Link>) : userAvatar }
                        <br/>
                        <p className="text-center">{post.user.name}</p>
                    </div>
                    <div className="col-md-10">
                        <p className="lead">{post.text}</p>
                        {auth.isAuthenticated && showActions ? (
                            <span>
                                <button
                                    onClick={this.onLikeClick.bind(this, post._id)}
                                    type="button"
                                    className="btn btn-light mr-1"
                                >
                                  <i
                                      className={classnames('fas fa-thumbs-up', {
                                          'text-info': this.findUserLike(post.likes)
                                      })}
                                  />
                                  <span className="badge badge-light">{post.likes.length}</span>
                                </button>
                                <button
                                    onClick={this.onUnlikeClick.bind(this, post._id)}
                                    type="button"
                                    className="btn btn-light mr-1"
                                >
                                  <i className="text-secondary fas fa-thumbs-down"/>
                                </button>
                                <Link to={`/post/${post._id}`} className="btn btn-info mr-1">
                                  Comments
                                </Link>
                                    {post.user._id === auth.user.id ? (
                                        <button
                                            onClick={this.onDeleteClick.bind(this, post._id)}
                                            type="button"
                                            className="btn btn-danger mr-1"
                                        >
                                            <i className="fas fa-times"/>
                                        </button>
                                    ) : null}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

PostItem.defaultProps = {
    showActions: true
};


PostItem.propTypes = {
    post: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    deletePost: PropTypes.func.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { deletePost, addLike, removeLike })(
    PostItem
);