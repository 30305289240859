import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PostItem from '../PostItem';
import Spinner from '../../common/Spinner';
import { getPost } from "../../../actions/postAction";
import CommentForm from './CommentForm';
import CommentFeed from "./CommentFeed";

class Post extends Component {

    componentDidMount() {
        this.props.getPost(this.props.match.params.id);
    }

    render() {

        const { post, loading } = this.props.post;
        const { isAuthenticated } = this.props.auth;

        const lineComments = (<h4>Comments</h4>);

        let postContent;

        if (post === null || loading || Object.keys(post).length === 0) {
            postContent = <Spinner />;
        } else {
            postContent = (
                <div>
                    <PostItem post={post} showActions={false} />
                    { isAuthenticated ? <CommentForm postId={post._id} /> : lineComments}
                    <CommentFeed comments={post.comments} postId={post._id}/>
                </div>
            );
        }

        return (
            <div className="post">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to="/posts" className="btn btn-light mb-3">
                                Back To Feed
                            </Link>
                            {postContent}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Post.propTypes = {
    auth: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    getPost: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    post: state.post,
    auth: state.auth
});

export default connect(mapStateToProps, { getPost })(Post);